<template>
    <custom-modal
        width="800px"
        title="导入班组人员"
        :visible="visible"
        @on-close="handleClose"
    >
        <template #customFooter>
            <a-button type="primary" @click.stop="handleConfirmImport">开始导入</a-button>
        </template>
        <batch-import ref="form" :data="batchImportData" />
    </custom-modal>
</template>
<script>
import { Button as AButton } from 'ant-design-vue'
import CustomModal from '@components/CustomModal'
import BatchImport from '@weights/BatchImport'

import confirmImportService from '@/service/importData/confirmImportService'

const BIZ_TYPE = 3

export default {
    name: 'BatchImportModal',
    components: {
        AButton,
        CustomModal,
        BatchImport
    },
    props: {
        visible: Boolean,
        teamId: String,
        projectCode: String
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        // 传入 BatchImport 的 data 传参
        batchImportData() {
            return {
                bizType: BIZ_TYPE,
                busiId: this.teamId,
                projectCode: this.projectCode
            }
        }
    },
    methods: {
        async handleConfirmImport() {
            const data = this.$refs.form.getFormData()

            if (!data.isSuccess) {
                this.$message.warn('请选择数据正确的文件')
                return
            }

            if (!data.importRecordRequestId) {
                this.$message.warning('请选择文件')
                return
            }

            await this.triggerImportEventServer(data)

            this.$emit('on-refresh')
            this.handleClose()
        },
        async triggerImportEventServer(data) {
            const { importRecordRequestId } = data
            if (data.isSuccess && importRecordRequestId) {
                try {
                    await confirmImportService({
                        importRecordRequestId,
                        bizType: data.bizType
                    })

                    this.$message.success('导入成功')
                } catch (error) {
                    this.$message.warning(error.message)
                }
            }
        },
        handleClose() {
            this.$emit('on-close')
        }
    }
}
</script>
